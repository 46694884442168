<template>
  <div style="height: 100%">
    <nesi-user-common>
      <template #content>
        <el-tabs type="card" @tab-click="tabChange">
          <el-tab-pane label="行业动态">
            <el-table
              :data="noticeList1"
              border
              style="width: 100%"
              :header-cell-style="{
                textAlign: 'center',
                backgroundColor: '#f5f7fa'
              }"
            >
              <el-table-column prop="title" align="left" label="标题" />
              <el-table-column
                prop="date"
                label="发布时间"
                align="center"
                width="180"
              />
              <el-table-column label="操作" width="100" align="center">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="list-btn"
                    @click="getDetail(scope.row)"
                    >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="total, prev, pager, next"
              @current-change="currentChange"
              :total="total"
              :page-size="form.pageSize"
              style="text-align: right; margin: 10px; 0"
            />
          </el-tab-pane>
<!--          :cell-style="{ textAlign: 'center' }"-->
          <el-tab-pane label="政策法规">
            <el-table
              :data="noticeList2"
              border
              style="width: 100%"
              :header-cell-style="{
                textAlign: 'center',
                backgroundColor: '#f5f7fa'
              }"
            >
              <el-table-column prop="title" label="标题" />
              <el-table-column prop="date" label="发布时间" width="180" align="center"/>
              <el-table-column label="操作" width="100" align="center">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="list-btn"
                    @click="getDetail(scope.row)"
                    >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="total, prev, pager, next"
              @current-change="currentChange"
              :total="total"
              :page-size="form.pageSize"
              style="text-align: right; margin: 10px; 0"
            />
          </el-tab-pane>
        </el-tabs>
      </template>
    </nesi-user-common>
    <el-dialog
      v-model="infoVisible"
      :title="title"
      width="70%"
      center
      :destroy-on-close="true"
      top="6vh"
      style="padding-top: 10px; position: relative"
    >
      <template #default>
        <el-scrollbar height="580px">
          <!-- <div class="publicTitle">{{ currentItem.title }}</div> -->
          <div v-if="currentItem.source">
            <div class="publicSource">
              来源：{{ currentItem.source }}
              <text class="publicTime">发布时间：{{ currentItem.date }}</text>
            </div>
          </div>
          <div class="publicContent" v-html="content"></div>
        </el-scrollbar>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { getNotice } from '@/api/home'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import router from '@/router'
defineComponent({
  NesiUserCommon
})
const form = reactive({
  category: '2',
  pageNo: 1,
  pageSize: 10
})

const total = ref(0)
const noticeList1 = ref([])
const noticeList2 = ref([])
const getNotices = async (data) => {
  const resp = await getNotice(data)
  const tempList = resp.records.map((item) => {
    return {
      title: item.title,
      id: item.id,
      date: item.publishedAt ? item.publishedAt.split(',')[0] : '--',
      content: item.content,
      source: item.source
    }
  })
  total.value = resp.total
  if (data.category === '2') {
    noticeList1.value = tempList
  } else {
    noticeList2.value = tempList
  }
}
const currentChange = (value) => {
  form.pageNo = value
  getNotices(form)
}
const infoVisible = ref(false)

const tabChange = (value) => {
  if (value.props.label === '政策法规') {
    form.category = '3'
  } else {
    form.category = '2'
  }
  form.pageNo = 1
  getNotices(form)
}
const currentItem = ref(null)
const content = ref('')
const title = ref('')
const getDetail = (item) => {
  const routeUrl = router.resolve({
    path: '/article',
    query: {
      id: item.id
    }
  })
  window.open(routeUrl.href, '_blank')

  /* infoVisible.value = true
  currentItem.value = item
  let tempContent = ''
  tempContent = item.content
  title.value = item.title
  tempContent = tempContent.replace(
    /src\s*=\s*&quot;\s*\//g,
    'style=&quot;&quot; src=&quot;' + 'https://www.nnfwzl.com' + '/'
  )
  tempContent = tempContent.replace(
    /href\s*=\s*&quot;\s*\//g,
    'style=&quot;width: 97%;&quot; href=&quot;' + 'https://www.nnfwzl.com' + '/'
  )
  tempContent = tempContent.replace(/&gt;/g, '>')
  tempContent = tempContent.replace(/&lt;/g, '<')
  tempContent = tempContent.replace(/&quot;/g, '"')
  tempContent = tempContent.replace(/&amp;nbsp;/g, ' ')
  tempContent = tempContent.replace(
    /<table\s*width\s*=\s*"[0-9]*"\s*style\s*="width:\s*[0-9]*px;">/g,
    '<table width="852" style="width: 100%;">'
  )
  content.value = tempContent */
}
onMounted(() => {
  // 行业资讯
  getNotices(form)
})
</script>
<style lang="scss" scoped>
.rent-info-wrapper {
  background-color: #ffffff;
  padding: 0 10px;
  color: #666;
  .rent-info-item {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      height: 55px;
      // line-height: 55px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: solid 1px #e5e5e5;
      .house {
        width: 40%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &.w-80 {
          width: 84%;
        }
      }
      .price {
        width: 16%;
      }
      .link {
        width: 11%;
        display: flex;
        align-items: center;
        .name {
          display: inline-block;
          width: 30px;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-radius: none;
}

.publicTitle {
  text-align: center;
  padding: 15px 0;
  font-size: 20px;
}
.publicSource {
  padding: 15px 20px;
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: space-between;
}
.publicContent {
  padding: 0 20px;
  font-size: 16px;
}
</style>
